export function getFieldLabelString(errorsArray, fieldName, shortDisplayName, initialDisplayName) {
  const err = errorsArray[fieldName];
  if (!err) return initialDisplayName || shortDisplayName;

  if (err.message) return err.message;
  switch (err.type) {
    case 'required':
      return `${shortDisplayName} benötigt`;
    case 'minLength':
      return `${shortDisplayName} zu kurz`;
    case 'maxLength':
      return `${shortDisplayName} zu lang`;
    case 'pattern':
      return `${shortDisplayName} ungültig`;
    default:
      return initialDisplayName || shortDisplayName;
  }
}
