import { createContext, useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import request from '../util/request';
import PropTypes from 'prop-types';
import CartContext from './CartContext';
import Cookies from 'js-cookie';

const initialContextState = {
  isShowLogin: false,
  redirectUrl: '',
  showLogin: () => {},
  hideLogin: () => {},
  loginDone: () => {},
  logoutDone: () => {},

  isLoggedIn: false,
  isLoggingOut: false,
  isAuthenticating: true,
  userData: {},
  updateUserData: () => {},
  fetchUserProfile: () => {},
};

const UserContext = createContext(initialContextState);
export default UserContext;

export const UserContextProvider = (props) => {
  const { fetchCart } = useContext(CartContext);

  const showLogin = (redirectUrl) => {
    setState((state) => ({
      ...state,
      isShowLogin: true,
      redirectUrl,
    }));
  };
  const hideLogin = () => {
    setState((state) => ({
      ...state,
      isShowLogin: false,
    }));
  };

  const setUserData = (data) => {
    setState((state) => ({
      ...state,
      isLoggingOut: false,
      isLoggedIn: true,
      isAuthenticating: false,
      userData: data,
    }));
  };

  const removeUserData = (data) => {
    Cookies.remove('logged-in');

    setState((state) => ({
      ...state,
      isLoggedIn: false,
      userData: {},
    }));
  };

  const updateUserData = (data) => {
    setState((state) => ({
      ...state,
      userData: {
        ...state.userData,
        ...data,
      },
    }));
  };

  const loginDone = () => {
    Cookies.set('logged-in', 'true');
    fetchUserProfile();
  };

  const logoutDone = () => {
    Cookies.remove('logged-in');
    setState((state) => ({
      ...state,
      isLoggingOut: true,
      isLoggedIn: false,
      userData: {},
    }));

    fetchCart();
  };

  const fetchUserProfile = useCallback(() => {
    const loggedId = Cookies.get('logged-in') === 'true';

    if (loggedId) {
      request('/api/profile/v1/getProfile', {
        method: 'GET',
      })
        .then((response) => {
          setUserData(response);
        })
        .catch(async (err) => {
          setState((state) => ({
            ...state,
            isLoggedIn: false,
            isAuthenticating: false,
          }));
        });
    }
  }, []);

  const [state, setState] = useState(() => ({
    ...initialContextState,
    showLogin,
    hideLogin,
    loginDone,
    logoutDone,
    updateUserData,
    fetchUserProfile,
  }));

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  useEffect(() => {
    function authorizationListener(event) {
      // console.log('received Event authorization:401');
      removeUserData();
    }

    if (!state.isLoggingOut) {
      document.addEventListener('authorization:401', authorizationListener);

      if (state.isLoggedIn) {
        fetchUserProfile();
      }

      return () => {
        document.removeEventListener('authorization:401', authorizationListener);
      };
    }
  }, [state.isLoggedIn, state.isLoggingOut]);

  return <UserContext.Provider value={state}>{props.children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
