import { useContext, useEffect, useState } from 'react';
import RestaurantContext from '../../Context/RestaurantContext';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';

function GoogleTagManagerComponent() {
  const [renderComponent, setRenderComponent] = useState(false);
  const { cookieConsent, env } = useContext(RestaurantContext);
  const router = useRouter();

  useEffect(() => {
    const tagManagerArgs = () => {
      switch (env.APP_ENV) {
        case 'production':
          return {
            gtmId: 'GTM-MB3MVDF',
            auth: '2Z3rbD5Y28od53D_FHkDgw',
            preview: 'env-1',
          };
        case 'staging':
          return {
            gtmId: 'GTM-MB3MVDF',
            auth: 'y1LSX7vjgKtZX7NnF1adGw',
            preview: 'env-4',
          };
        case 'development':
        default:
          return {
            gtmId: 'GTM-MB3MVDF',
            auth: 'hhaAte20srDl4V5L3Domxw',
            preview: 'env-3',
          };
      }
    };

    if (cookieConsent && renderComponent !== cookieConsent.acceptAll) {
      if (cookieConsent.acceptAll) {
        setRenderComponent(true);
        TagManager.initialize(tagManagerArgs());
      } else {
        setRenderComponent(false);
        // reload when switch from accept to decline, so tags are getting cleared
        router.reload(window.location.pathname);
      }
    }
  }, [cookieConsent, env, router, renderComponent]);

  return <></>;
}

export default GoogleTagManagerComponent;
