import { useState, createContext, useEffect, useContext, useCallback } from 'react';
import { getCart } from '../components/Cart/cartHelpers';
import RestaurantContext from './RestaurantContext';
import { toast } from 'react-toastify';
import _isEqual from 'lodash/isEqual';

const initialContextState = {
  cart: {
    items: [],
    meta: {
      totalPrice: 0,
      items: 0,
    },
    redeemedCoupons: [],
  },
  deliveryTimes: [],
  loadingCart: false,
  error: undefined,
  fetchCart: () => {},
  overrideCart: () => {},
  setRedeemedCoupons: () => {},
  refetchDeliveryTimes: () => {},
};

const CartContext = createContext(initialContextState);
export default CartContext;

export const CartContextProvider = (props) => {
  const { restaurantProps } = useContext(RestaurantContext);

  let blocked = false;
  const fetchCart = async () => {
    if (blocked) return;

    // loading
    blocked = true;
    setState((state) => ({
      ...state,
      error: undefined,
      loadingCart: true,
    }));

    // fetch
    try {
      const cartResult = await getCart(restaurantProps.restaurantId);

      if (cartResult.itemsRemoved) {
        toast.warning(
          `Mindestens ein Artikel ist nicht mehr verfügbar - bitte prüfen Sie Ihren Warenkorb`,
          {
            theme: 'colored',
          },
        );
      }

      blocked = false;
      setState((state) => ({
        ...state,
        cart: cartResult.cart,
        deliveryTimes: cartResult.deliveryTimes,
        loadingCart: false,
      }));
    } catch (error) {
      // todo: log in logger

      blocked = false;
      setState((state) => ({
        ...state,
        error: error.message,
        loadingCart: false,
      }));
    }
  };

  const refetchDeliveryTimes = async () => {
    const { deliveryTimes } = await getCart(restaurantProps.restaurantId);
    if (!_isEqual(state.deliveryTimes, deliveryTimes)) {
      setState((prev) => ({
        ...prev,
        deliveryTimes,
      }));
    }
  };

  const overrideCart = (newCart) => {
    setState((state) => ({
      ...state,
      cart: newCart,
      loadingCart: false,
    }));
  };

  const setRedeemedCoupons = (redeemedCoupons) => {
    setState((oldState) => ({
      ...oldState,
      cart: { ...oldState.cart, redeemedCoupons: redeemedCoupons },
    }));
  };

  const [state, setState] = useState(() => {
    return {
      ...initialContextState,
      fetchCart,
      setRedeemedCoupons,
      overrideCart,
      refetchDeliveryTimes,
    };
  });

  useEffect(() => {
    fetchCart();
  }, []);

  return <CartContext.Provider value={state}>{props.children}</CartContext.Provider>;
};
