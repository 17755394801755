import { createContext, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

const initialContextState = {
  article: undefined,
  configuration: undefined,
  uuid: null,
  setArticleWithConfiguration: () => {},
  resetArticleSelection: () => {},
  loadingCart: false,
  showConfigModal: false,
};

const ArticleConfigurationContext = createContext(initialContextState);

const initialArticleConfiguration = () => {
  /* deep copy */
  return JSON.parse(
    JSON.stringify({
      amount: 1,
      note: '',
      extras: [],
      specialRequests: [],
      attributes: [],
      menuOption: {},
    }),
  );
};

export const ArticleConfigurationContextProvider = (props) => {
  const setArticleWithConfiguration = (article, configuration, uuid, from = 'not set') => {
    setState((state) => ({
      ...state,
      uuid,
      article,
      configuration:
        configuration || !isEmpty(configuration) ? configuration : initialArticleConfiguration(),
    }));
  };
  const resetArticleSelection = () => {
    setState((state) => ({
      ...state,
      uuid: null,
      article: undefined,
      configuration: initialArticleConfiguration(),
    }));
  };

  const [state, setState] = useState(() => ({
    ...initialContextState,
    setArticleWithConfiguration,
    resetArticleSelection,
  }));

  useEffect(() => {
    if (state.article) {
      setState((state) => ({ ...state, showConfigModal: true }));
      document.querySelector('body').classList.add('no-scroll');
    } else {
      setState((state) => ({ ...state, showConfigModal: false }));
      document.querySelector('body').classList.remove('no-scroll');
    }
  }, [state.article]);

  return (
    <ArticleConfigurationContext.Provider value={state}>
      {props.children}
    </ArticleConfigurationContext.Provider>
  );
};

export default ArticleConfigurationContext;
