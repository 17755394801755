import { useState, useEffect, createContext, useCallback } from 'react';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import request from '../util/request';
import { COOKIE_RWG_TOKEN } from '../pages/_app';

const RestaurantContext = createContext({
  env: undefined,
  restaurantProps: {},
  isClosedByLastMoment: false,
  setIsClosedByLastMoment: () => {},
  getOverallStatus: () => {},
  cookieConsent: {
    acceptAll: false,
  },
  rwgToken: undefined,
  removeRwgToken: () => {},
  setCookieConsent: () => {},
  removeCookieConsent: () => {},
  pushToDataLayer: () => {},
  selectedDeliveryRegion: undefined,
  setSelectedDeliveryRegion: () => {},
  setIsPickUp: () => {},
  showCartModalForMobile: false,
  setShowCartModal: () => {},
  resetMenuSearchEmit: false,
  resetMenuSearch: () => {},
  isDeliveryRegionSelected: false,
  minutesUntilLastMomentSinceRefetch: undefined,
  refetchRestaurantProps: () => {},
});

export const SESSION_KEY_REGION_SELECTED = 'selectedRegion';
export const COOKIE_CONSENT_NAME = 'cookie-consent-store';
export const RestaurantContextProvider = (props) => {
  const setUpdatedStorePageInfo = (restaurantProps) => {
    // keep eventually added additional storePageInfo data - only override values, does not replace whole object.
    const newRestaurantProps = { ...state.restaurantProps, ...restaurantProps };
    setState({ ...state, restaurantProps: newRestaurantProps });
  };

  useSWR('refetchProfile', () => {
    refetchRestaurantProps();
  });

  const refetchRestaurantProps = async () => {
    const restaurantId = props.value.restaurantId;
    const res = await request(`/api/restaurants/v1/storePageInfo?restaurantId=${restaurantId}`, {
      method: 'GET',
    });

    res.restaurantId = restaurantId;
    res.onlyPickUp = !res.doesDelivery && res.doesPickUp;
    res.freeDeliveryFrom = res.freeDeliveryFrom === undefined ? null : res.freeDeliveryFrom;
    setUpdatedStorePageInfo(res);
    setIsClosedByLastMoment(isStoreCurrentlyClosedByLastMoment(res));
    setMinutesUntilLastMomentSinceRefetch(calcLastMinutes(state.restaurantProps));
  };

  function calcLastMinutes(restaurantProps) {
    if (restaurantProps.statusData?.payload?.lastMoment) {
      const closingTime = new Date(restaurantProps.statusData.payload.lastMoment).setSeconds(0, 0);
      return Math.ceil((closingTime - new Date().getTime()) / 60000);
    } else {
      return 0;
    }
  }

  const setMinutesUntilLastMomentSinceRefetch = (minutes) => {
    setState((state) => ({ ...state, minutesUntilLastMomentSinceRefetch: minutes }));
  };
  const setCookieConsent = (cookieValue, expires = 365) => {
    Cookies.set(COOKIE_CONSENT_NAME, JSON.stringify(cookieValue), {
      expires,
    });
    setState((state) => ({ ...state, cookieConsent: cookieValue }));
  };
  const setRwgToken = (cookiePlainValue, expires = 30) => {
    Cookies.set(COOKIE_RWG_TOKEN, cookiePlainValue, {
      expires,
    });
    setState((state) => ({ ...state, rwgToken: cookiePlainValue }));
  };
  const removeRwgToken = () => {
    Cookies.remove(COOKIE_RWG_TOKEN);
    setState((state) => ({ ...state, rwgToken: undefined }));
  };

  const setIsClosedByLastMoment = (isClosed) => {
    setState((state) => ({ ...state, isClosedByLastMoment: isClosed }));
  };
  const removeCookieConsent = () => {
    Cookies.remove(COOKIE_CONSENT_NAME);
    setState((state) => ({ ...state, cookieConsent: undefined }));
  };

  const getInitialCookieConsent = () => {
    const cookieValue = Cookies.get(COOKIE_CONSENT_NAME);
    if (cookieValue) return JSON.parse(cookieValue);
    return undefined;
  };

  const setSelectedDeliveryRegion = (deliveryRegion, fromZip, isPickUp = false) => {
    // store in session storage
    const objectToStore = deliveryRegion;
    objectToStore.fromZip = fromZip;
    objectToStore.isPickUp = isPickUp;

    sessionStorage?.setItem(SESSION_KEY_REGION_SELECTED, JSON.stringify(objectToStore));

    setState((state) => {
      return { ...state, selectedDeliveryRegion: objectToStore };
    });
  };

  const setIsPickUp = (isPickUp) => {
    const selectedDeliveryRegion = getSelectedDeliveryRegion();
    const { fromZip, ...deliveryRegion } = selectedDeliveryRegion;
    setSelectedDeliveryRegion(deliveryRegion, fromZip, isPickUp);
  };

  const setShowCartModal = (show) => {
    setState((state) => ({ ...state, showCartModalForMobile: show }));
  };

  const resetMenuSearch = () => {
    setState((state) => ({ ...state, resetMenuSearchEmit: true }));
    setTimeout(() => {
      setState((state) => ({ ...state, resetMenuSearchEmit: false }));
    }, 0);
  };

  const pushToDataLayer = (args) => {
    const consent = getInitialCookieConsent();
    if (consent?.acceptAll) {
      TagManager.dataLayer(args);
    }
  };

  function isStoreCurrentlyClosedByLastMoment(restaurantProps) {
    if (restaurantProps.statusData.payload?.lastMoment === undefined) return false;

    const lastMomentInMillis = new Date(restaurantProps.statusData.payload.lastMoment).setSeconds(
      0,
      0,
    );

    return lastMomentInMillis <= Date.now();
  }

  function getOverallStatus(restaurantProps) {
    let overallStatus;
    let openingStatus = restaurantProps.statusData.status;
    let groupStatus = restaurantProps.statusData.groupStatus;

    if (openingStatus === 'OPENED' && isStoreCurrentlyClosedByLastMoment(restaurantProps)) {
      overallStatus = 'CLOSED';
    } else if (openingStatus === 'CLOSED' && groupStatus === 'WILL_OPEN') {
      overallStatus = 'WILL_OPEN';
    } else {
      overallStatus = openingStatus;
    }
    return overallStatus;
  }

  const initState = {
    env: props.env,
    restaurantProps: props.value,
    isClosedByLastMoment: isStoreCurrentlyClosedByLastMoment(props.value),
    getOverallStatus,
    setIsClosedByLastMoment,
    cookieConsent: props.cookieConsent,
    setCookieConsent,
    rwgToken: props.rwgToken,
    setRwgToken,
    removeRwgToken,
    removeCookieConsent,
    pushToDataLayer,
    selectedDeliveryRegion: undefined,
    setSelectedDeliveryRegion,
    setIsPickUp,
    showCartModalForMobile: false,
    setShowCartModal,
    resetMenuSearchEmit: false,
    resetMenuSearch,
    isDeliveryRegionSelected: false,
    refetchRestaurantProps,
  };

  const [state, setState] = useState(initState);

  const getSelectedDeliveryRegion = useCallback(() => {
    // get from session storage
    const dataFromSessionStr = sessionStorage?.getItem(SESSION_KEY_REGION_SELECTED);
    let dataFromSession = dataFromSessionStr ? JSON.parse(dataFromSessionStr) : {};

    if (state.restaurantProps && !state.restaurantProps.doesDelivery) {
      dataFromSession = { ...dataFromSession, isPickUp: true };
    } else if (!state.restaurantProps.doesPickUp) {
      dataFromSession = { ...dataFromSession, isPickUp: false };
    }

    return dataFromSession;
  }, [state.restaurantProps]);

  // set fronted effect / not used when SSR
  useEffect(() => {
    const deliveryRegion = getSelectedDeliveryRegion();
    setState((state) => {
      return {
        ...state,
        selectedDeliveryRegion: deliveryRegion,
        cookieConsent: getInitialCookieConsent(),
      };
    });
  }, [getSelectedDeliveryRegion]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      isDeliveryRegionSelected:
        !state.restaurantProps.doesDelivery || (state.selectedDeliveryRegion?._id && true),
    }));
  }, [state.selectedDeliveryRegion, state.restaurantProps.doesDelivery]);

  useEffect(() => {
    if (state.showCartModalForMobile) {
      document.querySelector('body').classList.add('no-scroll');
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  }, [state.showCartModalForMobile]);

  return <RestaurantContext.Provider value={state}>{props.children}</RestaurantContext.Provider>;
};

RestaurantContextProvider.propTypes = {
  env: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default RestaurantContext;
