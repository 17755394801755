import { useEffect, useState, useContext } from 'react';

import Link from 'next/link';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RestaurantContext from '../Context/RestaurantContext';

import { Container } from 'react-bootstrap';
import CartIcon from './Cart/CartIcon';
import CartContext from '../Context/CartContext';
import UserAvatarComponent from './MemberArea/UserAvatarComponent';

const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 99;
  width: 100%;
  background: white;
  box-shadow: 0 3px 9px RGBA(133, 133, 133, 0.3);

  height: 50px;
  @media (min-width: 768px) {
    height: 64px;
  }

  opacity: ${(props) => (props.$show ? 100 : 0)};
  visibility: ${(props) => (props.$show ? 'visible' : 'hidden')};

  transition: ${(props) => {
    if (props.$show) {
      return 'opacity 100ms linear 0s';
    } else {
      return 'opacity 100ms linear 0s, visibility 0s linear 100ms';
    }
  }};
`;

const HeaderContainer = styled(Container)`
  display: flex;
  flex-flow: row;
  align-items: center;

  height: 100%;

  h1 {
    font-weight: 400;
    margin-bottom: 0;

    font-size: 16px;
    letter-spacing: 0.71px;
    @media (min-width: 768px) {
      letter-spacing: 1.02px;
      font-size: 23px;
    }
  }
`;

const IconsBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  justify-self: flex-end;
  align-items: center;

  #cart {
    height: 30px;
    fill: #000000;
    @media (min-width: 768px) {
      height: 38px;
    }
  }
`;

const AvatarIconWrapper = styled.div`
  display: block;
  cursor: pointer;
`;

const GrowDiv = styled.div`
  flex-grow: 1;
`;

function Header({ initiallyShown, ...rest }) {
  const { restaurantProps: storeProps } = useContext(RestaurantContext);
  const {
    cart: { meta },
  } = useContext(CartContext);

  const showThreshold = rest.showThreshold || 600;

  const [isVisible, setIsVisible] = useState(initiallyShown);

  useEffect(() => {
    if (!initiallyShown) {
      const handleScroll = () => {
        if (window.scrollY >= showThreshold && !isVisible) {
          setIsVisible(true);
        }
        if (window.scrollY < showThreshold && isVisible) {
          setIsVisible(false);
        }
      };

      // just trigger this so that the initial state
      // is updated as soon as the component is mounted
      // related: https://stackoverflow.com/a/63408216
      handleScroll();

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      setIsVisible(true);
    }
  }, [initiallyShown, isVisible, showThreshold]);

  return (
    <HeaderWrapper $show={isVisible} id="header">
      <HeaderContainer>
        <h1>
          <Link href="/" legacyBehavior>
            {storeProps.restaurantName}
          </Link>
        </h1>
        <GrowDiv />
        <IconsBox>
          <CartIcon count={meta.items} />
          <UserAvatarComponent inverse />
        </IconsBox>
      </HeaderContainer>
    </HeaderWrapper>
  );
}

Header.propTypes = {
  initiallyShown: PropTypes.bool,
  showThreshold: PropTypes.number,
};

export default Header;
