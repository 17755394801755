export function rewriteAssetPath(path, env) {
  if (!path) return;

  // do not rewrite our cdn
  if (path.startsWith('https://foodalley-image-storage')) return path;

  const app_env = process.env.APP_ENV || env;
  const splitted = path.split('/api/assets/')[1];

  let assetDestinationPath = '';
  switch (app_env) {
    case 'production':
      assetDestinationPath = 'https://api.v2.foodalley.de/general/v1/assets/:path*';
      break;
    case 'staging':
      assetDestinationPath = 'https://api.v2.qa.foodalley.de/general/v1/assets/:path*';
      break;
    case 'development':
    default:
      assetDestinationPath = 'http://localhost:8081/general/v1/assets/:path*';
      break;
  }

  return assetDestinationPath.replace(':path*', splitted);
}

export default rewriteAssetPath;
