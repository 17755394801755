import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

const iconMap = {
  position: () => import('./Icons/position'),
  foodmenu: () => import('./Icons/foodmenu'),
  'scooter-min': () => import('./Icons/scooter-min'),
  'e-mail': () => import('./Icons/email'),
  phone: () => import('./Icons/phone'),
  avatar: () => import('./Icons/avatar'),
  'avatar-dummy': () => import('./Icons/avatar-dummy'),
  cart: () => import('./Icons/cart'),
  'pt-amex': () => import('./Icons/paymenttype-amex'),
  'pt-card': () => import('./Icons/paymenttype-card'),
  'pt-cash': () => import('./Icons/paymenttype-cash'),
  'pt-master': () => import('./Icons/paymenttype-mastercard'),
  'pt-paypal': () => import('./Icons/paymenttype-paypal'),
  'pt-paypal-small': () => import('./Icons/paypal'),
  'pt-visa': () => import('./Icons/paymenttype-visa'),
  cookie: () => import('./Icons/cookie'),
  store: () => import('./Icons/store'),
  menu: () => import('./Icons/menu'),
  'arrow-back': () => import('./Icons/arrow-back'),
  'arrow-next': () => import('./Icons/arrow-next'),
  'arrow-thick': () => import('./Icons/arrow-thick'),
  watch: () => import('./Icons/watch'),
  cross: () => import('./Icons/cross'),
  'add-circle': () => import('./Icons/add-circle'),
  info: () => import('./Icons/info'),
  vegetarian: () => import('./Icons/vegetarian'),
  vegan: () => import('./Icons/vegan'),
  hot: () => import('./Icons/hot'),
  dropdown: () => import('./Icons/dropdown'),
  'dropdown-round': () => import('./Icons/dropdown-round'),
  check: () => import('./Icons/check'),
  'check-single': () => import('./Icons/check-single'),
  refresh: () => import('./Icons/refresh'),
  search: () => import('./Icons/search'),
  triangle: () => import('./Icons/triangle'),
  anonymous: () => import('./Icons/anonymous'),
  'money-bag': () => import('./Icons/money-bag'),
  trash: () => import('./Icons/trash'),
  'star-empty': () => import('./Icons/star-empty'),
  'star-full': () => import('./Icons/star-full'),
  lock: () => import('./Icons/lock'),
  upload: () => import('./Icons/upload'),
  'add-round': () => import('./Icons/addRound'),
  'illu-burger': () => import('./Icons/illustration-handy-burger'),
};

// Cache to store loaded icon components
const iconCache = {};
function Icon({ name, ...props }) {
  const [IconComponent, setIconComponent] = useState(() => iconCache[name] || null);

  useEffect(() => {
    // If icon is already cached, use it directly
    if (iconCache[name]) {
      setIconComponent(() => iconCache[name]);
    } else if (iconMap[name]) {
      // Dynamically import and cache the icon if not already cached
      iconMap[name]().then((module) => {
        iconCache[name] = module.default;
        setIconComponent(() => module.default);
      });
    }
  }, [name]);

  // Render the cached icon component or return null if loading for the first time
  return IconComponent ? <IconComponent {...props} /> : null;
}

Icon.propTypes = {
  style: PropTypes.object,
  name: PropTypes.oneOf(Object.keys(iconMap)),
  viewBox: PropTypes.string,
};

export default Icon;
