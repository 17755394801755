import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useState, useContext, useEffect } from 'react';
import { Row, Col, FormControl, Form, FloatingLabel, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import request, { resolveErrorBody } from '../../util/request';

import UserContext from '../../Context/UserContext';
import { getFieldLabelString } from '../../util/formHelpers';
import LoaderTyping from '../Loader/LoaderTyping';
import SocialLoginButtons from './SocialLoginButtons';
import { useRouter } from 'next/router';
import RestaurantContext from '../../Context/RestaurantContext';

const Wrapper = styled.div`
  @media (max-width: 991.98px) {
    padding-top: 34px;
  }
  padding-bottom: 35px;
  & .box-title {
    text-align: left;
    margin-bottom: 25px;

    h3 {
      margin: 0;
      font-size: 21px;
      letter-spacing: 0.93px;
    }
    p {
      font-weight: 300;
      margin: 0;
      font-size: 19px;
      letter-spacing: 0.84px;
    }
  }

  & .btn {
    width: 100%;
  }

  & .errorCol {
    text-align: center;
    color: #ec971f;
    font-size: 12px;
    letter-spacing: 0.56px;
    font-weight: 400;
  }
`;

const FormWrapper = styled.div`
  .row:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const LoginFormComponent = (props) => {
  const {
    formState: { errors },
    formState,
    setError,
    clearErrors,
    register,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const router = useRouter();

  const { loginDone, hideLogin, isLoggedIn } = useContext(UserContext);
  const { restaurantProps: storeProps } = useContext(RestaurantContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loginSuccess = () => {
    loginDone();
  };

  useEffect(() => {
    if (isLoggedIn) {
      hideLogin();
      if (props.redirectUrl) {
        router.push(props.redirectUrl);
      }
    }
  }, [isLoggedIn, hideLogin, router, props.redirectUrl]);

  const loginFail = (error) => {
    toast.error(
      <>
        <p>Konnte nicht einloggen.</p>
        <span>{error}</span>
      </>,
      {
        theme: 'colored',
      },
    );
  };

  const loginWithMail = (data, event) => {
    setIsSubmitting(true);

    request('/api/users/v1/login', {
      method: 'POST',
      body: JSON.stringify({
        email: data.email,
        password: data.password,
        restaurantId: storeProps.restaurantId,
      }),
    })
      .then(loginSuccess)
      .catch(async (error) => {
        const body = await resolveErrorBody(error);
        let detailMessage = body.error;

        if (body.key === 'UNCONFIRMED') {
          detailMessage = 'Nutzer bereits registriert.';
          setError('email', { type: 'custom', message: 'Nutzer bereits vorhanden' }, true);
        } else {
          setError('email', { type: 'auth', message: 'Zugangsdaten nicht korrekt' }, false);
          setError('password', { type: 'auth', message: 'Zugangsdaten nicht korrekt' }, true);
          setError('authentication', { type: 'auth', message: 'Zugangsdaten nicht korrekt' });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Wrapper>
      <Row>
        <Col xs={12} className="box-title">
          <h3>Anmeldung Mitgliederbereich</h3>
          <p>Du bist da! Der Tag ist gerettet.</p>
        </Col>
      </Row>

      <FormWrapper>
        <Form onSubmit={handleSubmit(loginWithMail)}>
          <Row>
            <Col xs={12}>
              <FloatingLabel
                controlId="email"
                label={getFieldLabelString(errors, 'email', 'E-Mail', 'E-Mail Adresse')}
                className="size-md"
              >
                <FormControl
                  type="email"
                  placeholder="E-Mail Adresse"
                  className="size-md"
                  isInvalid={errors.email && (formState.dirtyFields.email || formState.isSubmitted)}
                  isValid={!errors.email && formState.dirtyFields.email}
                  {...register('email', {
                    required: true,
                    maxLength: 50,
                    minLength: 2,
                    pattern: /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,10}$/i,
                  })}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FloatingLabel
                controlId="password"
                label={getFieldLabelString(errors, 'password', 'Passwort')}
                className="size-md"
              >
                <FormControl
                  type="password"
                  placeholder="Passwort"
                  className="size-md"
                  isInvalid={
                    errors.password && (formState.dirtyFields.password || formState.isSubmitted)
                  }
                  isValid={!errors.password && formState.dirtyFields.password}
                  {...register('password', {
                    required: true,
                    maxLength: 50,
                    minLength: {
                      value: 8,
                      message: 'Passwort zu kurz (min. 8 Zeichen)',
                    },
                  })}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="errorCol">
              {errors.authentication && errors.authentication.type === 'auth' && (
                <span>
                  Zugangsdaten sind ungültig. Bitte überprüfen Sie die eingegbene E-Mail und das
                  Passwort.
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Button
                variant="primary"
                size="lg"
                type="submit"
                className="button-submit"
                disabled={isSubmitting}
                onClick={() => clearErrors('authentication')}
              >
                {isSubmitting && <LoaderTyping style={{ marginRight: '10px' }} />}
                Login
              </Button>
            </Col>
          </Row>
        </Form>
        <Row>
          <SocialLoginButtons
            onGoogleSuccess={loginSuccess}
            onGoogleFail={loginFail}
            onFacebookSuccess={loginSuccess}
            onFacebookFail={loginFail}
          />
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              variant="outline"
              size="lg"
              type="button"
              className="button-submit"
              onClick={() => {
                hideLogin();
                router.push('/register');
              }}
            >
              Registrieren
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              style={{ fontSize: '14px' }}
              variant=""
              type="button"
              className="btn-link"
              onClick={() => {
                hideLogin();
                router.push('/reset-password');
              }}
            >
              Passwort vergessen
            </Button>
          </Col>
        </Row>
      </FormWrapper>
    </Wrapper>
  );
};

LoginFormComponent.propTypes = {
  redirectUrl: PropTypes.string,
};

export default LoginFormComponent;
