/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
async function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  const data = await response.text();
  if (data) {
    return JSON.parse(data);
  } else {
    return {};
  }
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401) {
    const event = new CustomEvent('authorization:401');
    document.dispatchEvent(event);
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export async function resolveErrorBody(error) {
  try {
    return await error.response.json();
  } catch (err) {
    // there is no json response
    return error;
  }
}

export function errorToStatusCodeString(e) {
  return e.response && e.response.status ? `Code: ${e.response.status}` : e;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function request(url, options) {
  const opt = {
    ...options,
  };
  if (!opt.headers) {
    opt.headers = {
      'Content-Type': 'application/json',
    };
  }
  return fetch(url, opt).then(checkStatus).then(parseJSON);
}

export default request;
