import { useContext, useEffect, useState } from 'react';
import RestaurantContext from '../../Context/RestaurantContext';
import Script from 'next/script';

function FacebookSdkConsentAware() {
  const [renderComponent, setRenderComponent] = useState(false);
  const { cookieConsent, env, restaurantProps: storeProps } = useContext(RestaurantContext);

  useEffect(() => {
    if (cookieConsent && renderComponent !== cookieConsent.acceptAll) {
      if (cookieConsent.acceptAll) {
        setRenderComponent(true);
      } else {
        setRenderComponent(false);
        delete window.fbAsyncInit;
      }
    }
  }, [cookieConsent, env, renderComponent]);

  function initFBSdk() {
    console.log('called');
    window.FB.init({
      appId: storeProps.faAppId,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v15.0',
    });
  }

  return renderComponent ? (
    <>
      <Script strategy="lazyOnload" id="fb-init">
        {`window.fbAsyncInit = function() {
          FB.init({
            appId            : '${storeProps.fbAppId}',
            autoLogAppEvents : true,
            xfbml            : true,
            version          : 'v15.0'
          });
        };`}
      </Script>
      <Script src="https://connect.facebook.net/en_US/sdk.js" strategy="lazyOnload" />
    </>
  ) : (
    <></>
  );
}

export default FacebookSdkConsentAware;
