import { createContext, useContext } from 'react';

// Define your global config
const intlConfig = {
  locale: 'de-DE',
  options: {
    currency: 'EUR',
    style: 'currency',
    maximumFractionDigits: 2,
  },
};

// Create the context
const IntlConfigContext = createContext(intlConfig);

// Provider component to wrap around your app
export const IntlProvider = ({ children }) => (
  <IntlConfigContext.Provider value={intlConfig}>{children}</IntlConfigContext.Provider>
);

// Custom hook to access config anywhere in the app
export const useIntlConfig = () => useContext(IntlConfigContext);
