import { useState, useEffect, useContext, useRef } from 'react';
import { useRouter } from 'next/router';
import RestaurantContext from '../../Context/RestaurantContext';
import PropTypes from 'prop-types';
import styled, { withTheme, css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import ArticleConfigurationContext from '../../Context/ArticleConfigurationContext';

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;

  height: 64px;
  overflow: hidden;
  width: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const NumberWrapper = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  user-select: none;

  font-size: 15px;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  color: inherit;
`;

const CartNumber = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 14px;
  top: 10px;
  right: 5px;

  color: white;
  font-size: 12px;
  font-weight: 700;

  span {
    padding-left: 1px;
  }

  ${(props) => {
    if (props.$shake) {
      return css`
        animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      `;
    } else {
      return css`
        animation: none;
      `;
    }
  }}

  @keyframes shake {
    20%,
    60% {
      transform: translate3d(0, -1px, 0);
    }

    40%,
    80% {
      transform: translate3d(0, 1px, 0);
    }
  }
`;

function CartIcon({ count, white, ...props }) {
  const segmentNothingInCart = [0, 1];
  const firstAnimationSegments = [0, 90];
  const articleAddedAnimationSegments = [90, 180];
  const articleRemoveAnimationSegments = [180, 255];
  const lastArticleRemovedAnimationSegments = [255, 300];

  const [fromCount, setFromCount] = useState(count);
  const [displayCount, setDisplayCount] = useState(count);
  const [play, setPlay] = useState(false);
  const [segments, setSegments] = useState(segmentNothingInCart);
  const [shake, setShake] = useState(false);

  const { setShowCartModal } = useContext(RestaurantContext);
  const { resetArticleSelection } = useContext(ArticleConfigurationContext);

  const isCartAlwaysVisibleInScreen = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const { push } = useRouter();
  const cartIconClicked = () => {
    if (isCartAlwaysVisibleInScreen) {
      resetArticleSelection();
      // move to /menu if not already there
      push('/menu');
    } else {
      // show cartModal
      setShowCartModal(true);
    }
  };

  useEffect(() => {
    // nothing in cart, or initial count -> no animation
    if (fromCount === count) {
      // begin (no animation)
      setSegments(segmentNothingInCart);
      return;
    }

    // select correct animation segments
    if (fromCount < count && count === 1) {
      // first add animation
      setSegments(firstAnimationSegments);
    } else if (fromCount < count && count > 1) {
      // add animation
      setSegments(articleAddedAnimationSegments);
    } else if (fromCount > count && count !== 0) {
      // remove animation
      setSegments(articleRemoveAnimationSegments);
    } else if (fromCount > count && count === 0) {
      // last article removed animation
      setSegments(lastArticleRemovedAnimationSegments);
    }

    setFromCount(count);
    setPlay(true);

    // animate to number holder shake effect
    setTimeout(() => {
      setDisplayCount(count);
      setShake(true);

      setTimeout(() => {
        setShake(false);
      }, 800);
    }, 500);
  }, [count]);

  const lottieRef = useRef();
  useEffect(() => {
    import('@dotlottie/player-component');
  }, []);

  const [animationReady, setAnimationReady] = useState(false);
  useEffect(() => {
    const lottieReadyCb = (e) => {
      setAnimationReady(true);
    };
    if (!animationReady) {
      lottieRef.current.addEventListener('ready', lottieReadyCb);

      return () => {
        lottieRef.current?.removeEventListener('ready', lottieReadyCb);
      };
    }
  }, [segments, lottieRef, animationReady]);

  const stopEventListenerFn = useRef();

  useEffect(() => {
    const stopAfterFrame = (event, stopAfterFrame) => {
      const enteredFrame = event.detail.frame;
      if (enteredFrame >= stopAfterFrame) {
        event.target.stop();
        event.target.reset();
        setPlay(false);
      }
    };

    if (animationReady && play) {
      lottieRef.current.seek(segments[0]);
      lottieRef.current.play();
      stopEventListenerFn.current = (e) => stopAfterFrame(e, segments[1]);
      lottieRef.current.addEventListener('frame', stopEventListenerFn.current);

      return () => {
        lottieRef.current?.removeEventListener('frame', stopEventListenerFn.current);
      };
    }
  }, [animationReady, segments, play]);

  return (
    <Wrapper onClick={cartIconClicked}>
      <div
        style={{
          width: 300,
          height: 300,
          marginTop: 4,
          filter: white ? 'drop-shadow(0px 0px 7px rgba(0, 0, 0, 1))' : 'none',
        }}
      >
        <dotlottie-player
          ref={lottieRef}
          id="cartLottie"
          mode="normal"
          src="/LottieFiles/lottie-cart.json"
          style={{ width: 300, height: 300, filter: white ? 'invert(1)' : 'none' }}
        />
      </div>
      <CartNumber $shake={shake}>
        <span>{displayCount}</span>
      </CartNumber>
    </Wrapper>
  );
}

CartIcon.propTypes = {
  count: PropTypes.number,
  white: PropTypes.bool,
};

export default withTheme(CartIcon);
