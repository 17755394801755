import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';

import Icon from '../Icon';
import UserContext from '../../Context/UserContext';
import { useRouter } from 'next/router';

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  #avatar {
    height: 100%;
    width: 100%;
    fill: #000000;
  }
`;

const UserProfileWrapper = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  width: 37px;
  height: 37px;

  border-radius: 19px;
  border: 1px solid rgba(133, 133, 133, 0.3);

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  & picture {
    width: 100%;
    height: 100%;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

function UserAvatarComponent(props) {
  const router = useRouter();
  const { showLogin, isLoggedIn, userData: { profileImageUrl } = {} } = useContext(UserContext);

  const [inverse, setInverse] = useState();
  useEffect(() => {
    setInverse(props.inverse);
  }, [props.inverse]);

  return (
    <Wrapper onClick={() => (isLoggedIn ? router.push('/memberarea') : showLogin())}>
      {isLoggedIn ? (
        <>
          {profileImageUrl ? (
            <UserProfileWrapper>
              <picture>
                <source srcSet={profileImageUrl} />
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img src={profileImageUrl} alt="Profile Image" referrerPolicy="no-referrer" />
              </picture>
            </UserProfileWrapper>
          ) : (
            <UserProfileWrapper>
              <Icon
                id="avatar"
                name="avatar-dummy"
                style={{
                  fill: '#ffffff',
                }}
              />
            </UserProfileWrapper>
          )}
        </>
      ) : (
        <Icon
          id="avatar"
          name="avatar"
          style={{
            fill: inverse ? '#000000' : '#ffffff',
            filter: inverse ? '' : 'drop-shadow(0px 0px 7px rgba(0, 0, 0, 1)',
          }}
        />
      )}
    </Wrapper>
  );
}

UserAvatarComponent.propTypes = {
  inverse: PropTypes.bool,
};

export default UserAvatarComponent;
