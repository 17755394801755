import rewriteAssetPath from './rewriteAssetPath';

export function generateStoreLd(restaurantData, env, url) {
  const restaurant = {
    '@context': 'https://schema.org',
    '@type': 'Restaurant',
    image: [
      rewriteAssetPath(restaurantData.titleImagePath, env.APP_ENV),
      rewriteAssetPath(restaurantData.platformLogoPath, env.APP_ENV),
    ],
    logo: rewriteAssetPath(restaurantData.platformLogoPath, env.APP_ENV),
    name: restaurantData.restaurantName,
    priceRange: '€€',
    address: {
      '@type': 'PostalAddress',
      streetAddress: `${restaurantData.address.street} ${restaurantData.address.houseNumber}`,
      addressLocality: restaurantData.address.city,
      postalCode: restaurantData.address.zip,
      addressCountry: 'DE',
    },
    url: url,
    telephone: restaurantData.telephoneNumber,
    servesCuisine:
      restaurantData.storeCategories &&
      restaurantData.storeCategories.map((cat) => cat.name).join(', '),
    menu: url + '/menu',
    acceptsReservations: 'True',
  };

  if (restaurantData.rating) {
    restaurant.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: restaurantData.rating.toFixed(2), //hier
      ratingCount: restaurantData.numberOfRatings, //hier
      reviewCount: restaurantData.numberOfRatings, //hier
      bestRating: 5,
      worstRating: 1,
    };
  }

  if (restaurantData.gpsLocation) {
    restaurant.geo = {
      '@type': 'GeoCoordinates',
      latitude: restaurantData.gpsLocation.lat, //hier+
      longitude: restaurantData.gpsLocation.lng, //hier+
    };
  }

  return JSON.stringify(restaurant);
}
