import isEmpty from 'lodash/isEmpty';
import request from '../../util/request';
import { toast } from 'react-toastify';

export function addArticleToCart(uuid, article, configuration, restaurantId) {
  const payload = {
    uuid,
    article,
    configuration,
    restaurantId,
  };
  const preparedData = prepareArticleData(payload);

  return request('/api/cart/v1/add', {
    method: 'POST',
    body: JSON.stringify(preparedData),
  });
}

export function increaseArticleAmount(uuid, restaurantId) {
  const payload = {
    uuid,
    restaurantId,
  };

  return request('/api/cart/v1/increaseAmount', {
    method: 'POST',
    body: JSON.stringify(payload),
  }).then((result) => {
    checkForCartUpdateOnResult(result);
    return mapResponseToDesiredFormat(result);
  });
}

export function decreaseArticleAmount(uuid, restaurantId) {
  const payload = {
    uuid,
    restaurantId,
  };

  return request('/api/cart/v1/decreaseAmount', {
    method: 'POST',
    body: JSON.stringify(payload),
  }).then((result) => {
    checkForCartUpdateOnResult(result);
    return mapResponseToDesiredFormat(result);
  });
}

function checkForCartUpdateOnResult(responseData) {
  if (responseData.itemsRemoved) {
    toast.warning(
      `Mindestens ein Artikel ist nicht mehr verfügbar - bitte prüfen Sie Ihren Warenkorb`,
      {
        theme: 'colored',
      },
    );
  }
}

export function getCart(restaurantId) {
  return request(`/api/cart/v1/get?restaurantId=${restaurantId}`).then((result) => {
    return mapCartResponseToDesiredFormat(result);
  });
}

export function recoverCart(restaurantId, orderId, reason, skipOrderUpdate = false) {
  return request('/api/cart/v1/recoverCart', {
    method: 'POST',
    body: JSON.stringify({
      restaurantId,
      orderId,
      reason,
      skipOrderUpdate,
    }),
  });
}

export function clearCartOnServer(restaurantId) {
  return request('/api/cart/v1/clearCart', {
    method: 'POST',
    body: JSON.stringify({
      restaurantId,
    }),
  });
}

function mapCartResponseToDesiredFormat(data) {
  const dt = {
    ...data,
    cart: mapResponseToDesiredFormat(data.cart),
  };
  return dt;
}

function mapResponseToDesiredFormat(data) {
  data.items.forEach((item) => {
    item.articleId = item.fullArticle._id;

    if (item.configuration) {
      item.configuration.attributes.forEach((attribute) => {
        attribute.attribute = item.fullArticle.attributes.find(
          (articleAttribute) => articleAttribute._id === attribute._id,
        );
        attribute.selectedValue = attribute.attribute.values.find(
          (el) => el._id === attribute.selectedValueId,
        );
      });

      if (item.configuration?.menuOption) {
        item.configuration.menuOption.option = {
          _id: item.configuration.menuOption._id,
          title: item.configuration.menuOption.title,
          price: item.configuration.menuOption.price,
        };
      }

      item.configuration.menuOption?.selectedAttributes?.forEach((selectedAttr) => {
        selectedAttr.attribute = { title: selectedAttr.title };
        selectedAttr.selectedValue = { _id: selectedAttr.selectedValueId };
      });
    }
  });
  return data;
}

function prepareArticleData(data) {
  if (isEmpty(data.configuration)) {
    data.configuration = {
      attributes: [],
      extras: [],
      specialRequests: [],
    };
  }
  return {
    restaurantId: data.restaurantId,
    uuid: data.uuid,
    articleId: data.article._id,
    amount: data.configuration.amount || 1,
    note: data.configuration.note ? data.configuration.note.trim() : data.configuration.note,
    configuration: {
      attributes: data.configuration.attributes.map((attr) => ({
        _id: attr.attribute._id,
        selectedValueId: attr.selectedValue._id,
      })),
      extras: data.configuration.extras.map((extra) => ({
        _id: extra._id,
        amount: extra.amount,
      })),
      specialRequests: data.configuration.specialRequests.map((sr) => ({
        _id: sr._id,
        amount: sr.amount,
      })),
      menuOption: _prepareMenuOption(data.configuration.menuOption),
    },
  };
}

function _prepareMenuOption(menuOption) {
  if (!menuOption || isEmpty(menuOption)) return undefined;

  return {
    _id: menuOption.option._id,
    selectedAttributes: menuOption.selectedAttributes.map((attr) => ({
      ...attr.attribute,
      selectedValueId: attr.selectedValue._id,
    })),
  };
}

const utils = {
  prepareArticleData,
  addArticleToCart,
  increaseArticleAmount,
  decreaseArticleAmount,
};
export default utils;
