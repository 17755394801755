import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useState, useContext, useEffect, useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import request, { resolveErrorBody } from '../../util/request';

import RestaurantContext from '../../Context/RestaurantContext';
import LoaderTyping from '../Loader/LoaderTyping';

const SocialLoginWrapper = styled.div`
  & .btn,
  & button {
    width: 100%;
  }
  & button:disabled,
  & button[disabled] {
    pointer-events: none;
    opacity: 0.65;
  }

  & .googleRainbow {
    background-image: linear-gradient(
      90deg,
      RGB(66, 133, 244),
      RGB(219, 68, 55),
      RGB(244, 180, 0),
      RGB(15, 157, 88)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & .facebook {
    color: RGB(66, 103, 178);
  }
`;

const DividerWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const DividerSolid = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background-color: RGBA(133, 133, 133, 0.15);
`;
const VerticallyCenteredDivider = () => {
  return (
    <DividerWrapper>
      <DividerSolid>&nbsp;</DividerSolid>
    </DividerWrapper>
  );
};

const SocialLoginButtons = ({
  onGoogleSuccess,
  onGoogleFail,
  onFacebookSuccess,
  onFacebookFail,
}) => {
  const [isSubmittingFacebook, setIsSubmittingFacebook] = useState(false);
  let fbClicked = false;

  const [isSubmittingGoogle, setIsSubmittingGoogle] = useState(false);
  const [onClient, setOnClient] = useState(typeof window !== undefined);
  const [fbLoaded, setFbLoaded] = useState(false);

  const { restaurantProps: storeProps } = useContext(RestaurantContext);

  const attemptNumber = useRef(0);
  useEffect(() => {
    const maxAttempts = 100;
    function checkIfFbIsLoaded() {
      if (window.FB) {
        setFbLoaded(true);
        return;
      }

      setTimeout(() => {
        if (window.FB) {
          setFbLoaded(true);
        } else if (attemptNumber.current < maxAttempts) {
          attemptNumber.current++;
          checkIfFbIsLoaded();
        }
      }, 50);
    }

    setOnClient(true);
    checkIfFbIsLoaded();
  }, [fbLoaded]);

  const loginWithGoogle = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (tokenResponse) => {
      setIsSubmittingGoogle(true);
      request('/api/users/v1/googleLogin', {
        method: 'POST',
        body: JSON.stringify({
          authCode: tokenResponse.code,
          restaurantId: storeProps.restaurantId,
        }),
      })
        .then((result) => {
          onGoogleSuccess(result);
        })
        .catch(async (error) => {
          const body = await resolveErrorBody(error);
          onGoogleFail(body);
        })
        .finally(() => {
          setIsSubmittingGoogle(false);
        });
    },
    onError: onGoogleFail,
  });

  const loginWithFacebook = () => {
    if (window?.FB) {
      fbClicked = true;
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            FB.api('/me', function (meResponse) {
              facebookCallback({
                authResponse: response.authResponse,
                status: response.status,
                facebookUserInfo: meResponse,
              });
            });
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        },
        { scope: 'public_profile,email' },
      );
    }
  };

  const facebookCallback = (result) => {
    if (result?.authResponse && result?.status === 'connected' && fbClicked) {
      setIsSubmittingFacebook(true);

      request('/api/users/v1/facebookLogin', {
        method: 'POST',
        body: JSON.stringify({
          accessToken: result.authResponse.accessToken,
          userId: result.authResponse.userID,
          restaurantId: storeProps.restaurantId,
        }),
      })
        .then(() => {
          onFacebookSuccess();
        })
        .catch(async (error) => {
          const body = await resolveErrorBody(error);
          onFacebookFail(body);
        })
        .finally(() => {
          (fbClicked = false), setIsSubmittingFacebook(false);
        });
    }
  };

  return (
    <SocialLoginWrapper>
      <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
        <Col xs={5}>
          <VerticallyCenteredDivider />
        </Col>
        <Col xs={2} className="text-center" style={{ fontSize: '11px', letterSpacing: '0.51px' }}>
          oder
        </Col>
        <Col xs={5}>
          <VerticallyCenteredDivider />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="outline"
            size="lg"
            type="button"
            className="google"
            onClick={loginWithGoogle}
            disabled={isSubmittingGoogle}
          >
            {isSubmittingGoogle && (
              <LoaderTyping
                style={{ marginRight: '10px' }}
                dotStyle={{ background: 'rgba(133,133,133,1)' }}
              />
            )}
            <span className="googleRainbow">Google</span>
          </Button>
        </Col>
        {onClient && storeProps.fbAppId && fbLoaded && (
          <Col>
            <Button
              variant="outline"
              size="lg"
              type="button"
              className="google"
              onClick={loginWithFacebook}
              disabled={isSubmittingFacebook}
            >
              {isSubmittingFacebook && (
                <LoaderTyping
                  style={{ marginRight: '10px' }}
                  dotStyle={{ background: 'rgba(133,133,133,1)' }}
                />
              )}
              <span className="facebook">Facebook</span>
            </Button>
          </Col>
        )}
      </Row>
    </SocialLoginWrapper>
  );
};

SocialLoginButtons.propTypes = {
  onGoogleSuccess: PropTypes.func.isRequired,
  onGoogleFail: PropTypes.func.isRequired,
  onFacebookSuccess: PropTypes.func.isRequired,
  onFacebookFail: PropTypes.func.isRequired,
};

export default SocialLoginButtons;
