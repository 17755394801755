import { useContext } from 'react';
import styled from 'styled-components';
import { Modal, ModalDialog, Row, Col } from 'react-bootstrap';
import Icon from '../Icon';
import UserContext from '../../Context/UserContext';
import LoginFormComponent from './LoginFormComponent';
import { GoogleOAuthProvider } from '@react-oauth/google';

const FaModal = styled(ModalDialog)`
  .modal-content {
    padding-top: 25px;
    padding-bottom: 0;
  }

  .modal-body {
    padding: 0 15px;
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  @media (min-width: 992px) {
    top: 35px;
    left: 35px;
  }

  z-index: 99;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background: white;
  box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.13);
  &:hover {
    box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.25);
  }
  transition: box-shadow 0.2s;
  text-align: center;
  user-select: none;
  cursor: pointer;

  & > svg {
    margin-top: 8px;
    vertical-align: bottom;
  }
`;

const IlluWrapper = styled.div`
  margin-left: -15px;
  width: 100%;
`;

function LoginModal({ onHide = () => {}, ...props }) {
  const { isShowLogin, hideLogin, redirectUrl } = useContext(UserContext);

  return (
    <div>
      <Modal
        fullscreen={'sm-down'}
        dialogAs={FaModal}
        show={isShowLogin}
        onHide={hideLogin}
        size="lg"
        {...props}
        centered
      >
        <CloseButtonWrapper onClick={hideLogin}>
          <Icon style={{ width: 18 }} name="cross" />
        </CloseButtonWrapper>
        <Modal.Body>
          <Row>
            <Col className="d-none d-lg-flex" lg={6} style={{ alignItems: 'end' }}>
              <IlluWrapper>
                <Icon name="illu-burger" />
              </IlluWrapper>
            </Col>
            <Col xs={12} lg={6}>
              <GoogleOAuthProvider clientId="723913101097-enpmnfbivabvvlq84dmdq4q0pnerdqab.apps.googleusercontent.com">
                <LoginFormComponent redirectUrl={redirectUrl} />
              </GoogleOAuthProvider>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}

LoginModal.propTypes = {};

export default LoginModal;
