import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    :root {
        --primary: #000000;
    }
    html {
        height: 100%;
        background-color: #484f51;
    }
    html,
    body {
        padding: 0;
        margin: 0;
        width: 100%;
        scroll-behavior: smooth;
        background-color: #484f51;
    }

    body {
        font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        letter-spacing: 0.75px;
        overflow: auto;
        scroll-behavior: smooth;

        &.no-scroll {
            @media (max-width: 991.98px) {
                overflow: hidden;
                -webkit-overflow-scrolling: touch;
            }
        }
    }

    a {
        color: inherit;
        text-decoration: none;

        &.colored {
            color: var(--bs-primary);
        }
    }
    

    * {
        box-sizing: border-box;
    }

    h1 {
        font-size: 27px;
        font-weight: 700;
        letter-spacing: 1.2px;
    }

    h2 {
        @media (min-width: 768px) {
            font-size: 23px;
        }
        font-size: 20px;
        color: rgb(0, 0, 0);
        font-weight: 700;
        letter-spacing: 0.89px;

        margin-bottom: 0;
    }

    h3 {
        @media (min-width: 768px) {
            font-size: 14px;
        }
        font-size: 14px;
        color: rgb(72, 79, 81);
        font-weight: 700;
        letter-spacing: 0.62px;
    }

    .nowrap {
        white-space: nowrap;
    }

    .input-group .form-floating input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .social-fb-login-button-wrapper > button {
        background-color: #ffffff !important;
        color: rgba(133, 133, 133, 1) !important;
        border: 1px solid rgba(133, 133, 133, 0.15) !important;
        width: 100%;
        padding: 0.5rem 1rem !important;
        font-size: 1.25rem !important;
        border-radius: 0.3rem !important;
        font-weight: 400!important;
        line-height: 1.5!important;
        height: auto !important;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }    
    .social-fb-login-button-wrapper > button:hover {
        color: #212529 !important;
    }
    .social-fb-login-button-wrapper > button:focus {
        box-shadow: 0 0 0 0.25rem rgb(108 117 125 / 50%);
    }
    .social-fb-login-button-wrapper > button:active {
        color: #fff;
        background-color: rgba(133, 133, 133, 1);
        border-color: rgba(133, 133, 133, 0.15);
    }

    .btn-outline {
        color: rgba(133, 133, 133, 1);
        border-color: rgba(133, 133, 133, 0.15);
        &:hover {
            border-color: rgba(133, 133, 133, 0.3);
        }
    }
    .btn-check:focus+.btn-outline, .btn-outline:focus {
        box-shadow: 0 0 0 0.25rem rgb(108 117 125 / 50%);
    }
    .btn-check:active+.btn-outline, .btn-check:checked+.btn-outline, .btn-outline.active, .btn-outline.dropdown-toggle.show, .btn-outline:active {
        color: #fff;
        background-color: rgba(133, 133, 133, 1);
        border-color: rgba(133, 133, 133, 0.15);
    }

    .btn-outline-secondary {
        color: #6c757d;
        border-color: #6c757d;
    }
    .btn-check:focus+.btn-outline-secondary, .btn-outline-secondary:focus {
        box-shadow: 0 0 0 0.25rem rgb(108 117 125 / 50%);
    }
    .btn-check:active+.btn-outline-secondary, .btn-check:checked+.btn-outline-secondary, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show, .btn-outline-secondary:active {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
    }

    button {
        color: #000000;
    }

    .btn-link {
        color: inherit;
        font-weight: inherit;
        text-decoration: inherit;
        padding: 0;
        font-size: inherit;
        letter-spacing: inherit;
        box-shadow: none!important;

        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }

    .btn:focus-visible {
        box-shadow: 0 0 0 0.25rem rgb(47 159 47 / 50%) !important;

        &.btn-outline-primary {
            background-color: white;
            color: inherit;
        }
    }
    .btn-outline-primary.disabled:active {
        color: white;
    }

    input.form-control {
        border: 1px solid rgba(133,133,133,0.15);
    }

    /* Custom Floating Input with custom height amk */
    .form-control.size-md {
        height: 50px;
    }
    .form-floating.size-md > label {
        line-height: 50px;
        padding-top: 3px;
        padding-bottom: 0;
        padding-left: 0.75rem
    }
    .form-floating.size-md > .form-control.size-md:focus ~ label, .form-floating.size-md > .form-control.size-md:not(:placeholder-shown) ~ label, .form-floating.size-md > .form-select.size-md ~ label {
        transform: scale(0.75) translateY(-0.5rem) translateX(0.33rem);
    }
    .form-floating.size-md > .form-control.size-md:focus, .form-floating.size-md > .form-control.size-md:not(:placeholder-shown) {
        padding-top: 1.5rem;
    }
    .form-floating > label {
        white-space: nowrap;
    }

    /* validation */
    .form-control.is-invalid + label {
        color: var(--bs-warning);
    }
    .form-control.is-valid {
        border-color: #97cf97;

        background-position: right calc(0.375em + 0.15rem) top 1.5rem;
        background-size: calc(0.75em + 0.2rem) calc(0.75em + 0.2rem);
    }
    .form-control.is-invalid {
        border-color: #ec971f;
    }

    select,   textarea,   input[type="text"],   input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] { font-size: 16px !important; }
`;

export default GlobalStyle;
